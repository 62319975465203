import siteInfo from "@/constants/siteInfo";
import dynamic from "next/dynamic";
//import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import SVG from "react-inlinesvg";
import { useEffect } from "react";
import sendGAEvent from "@/utils/gaEvent";
import NavLink from "../Navlink";

const MobileMenuSlider = dynamic(() => import("./MobileMenuSlider"));

const MobileNavbar = ({ header_menu = [] }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [IsSubCategOpen, setIsSubCategOpen] = useState(false);
  const router = useRouter();
  const isRajasthan = router?.asPath?.includes("/rajasthan");
  const isMadhya = router?.asPath?.includes("/madhya-pradesh");
  const isHR = router?.asPath?.includes("/haryana");
  const isBH = router?.asPath?.includes("/bihar");
  const isUP = router?.asPath?.includes("/uttar-pradesh");
  const isDL = router?.asPath?.includes("/delhi");


  const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;

  const handleSearch = () => {
    if (!searchText?.trim()?.length > 0) return;
    setIsDrawerOpen(false);
    setSearchText("");
    setIsSearchBoxOpen(false);
    let eventName = `view_search_results`;
    sendGAEvent({
      eventName: eventName,
      pageTitle: document?.title,
      pageLocation: window?.location?.href,
      searchQuery: searchText?.trim(),
    });
    window.location.href = `${
      process.env.NEXT_PUBLIC_WEBSITE_URL
    }/search/${encodeURIComponent(searchText?.trim())}`;
    // setSearchText("");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchText?.trim()?.length > 0) {
      setIsDrawerOpen(false);
      handleSearch();
      // Blur the input field to close the keyboard
      event.target.blur();
    }
  };

  useEffect(() => {
    if (isDrawerOpen) {
      // Prevent scrolling
      document.body.style.overflow = "hidden";
      let element = document.querySelectorAll(".vdo_floating");
      let element2 = document.querySelectorAll("#vdo_logo_parent");

      for (var i = 0; i < element.length; i++) {
        element[i].style.setProperty("z-index", "0", "important");
      }
      for (var i = 0; i < element2.length; i++) {
        element2[i].style.setProperty("display", "none", "important");
      }
    } else {
      // Re-enable scrolling
      document.body.style.overflow = "";
      let element = document.querySelectorAll(".vdo_floating");
      let element2 = document.querySelectorAll("#vdo_logo_parent");

      for (var i = 0; i < element.length; i++) {
        element[i].style.setProperty("z-index", "1", "important");
      }
      for (var i = 0; i < element2.length; i++) {
        element2[i].style.setProperty("display", "block", "important");
      }
    }

    // Cleanup function to re-enable scrolling when the component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, [isDrawerOpen]);
  // console.log(websiteUrl+(isMadhya?'/madhya-pradesh':isRajasthan?'/rajasthan':isUP?'/uttar-pradesh':isHR?'/haryana':isBH?'/bihar':isDL?'/delhi-news':'/'))
  return (
    <>
      <nav className={`w-full top-0 z-50 bg-white shadow-md`}>
        <div className=" flex items-center justify-between px-2 py-1">
          <div className="flex items-center">
            <button
              className="min-w-[35px] text-gray-500 focus:outline-none"
              onClick={() => {
                let eventName = `TopBar-HamburgerMenu`;
                sendGAEvent({
                  eventName: eventName,
                  pageTitle: document?.title,
                  pageLocation: window?.location?.href,
                });
                setIsDrawerOpen(!isDrawerOpen);
              }}
            >
              <img
                className="min-w-[35px]"
                src="/assets/icons/svg/menu.svg"
                alt="menu icon"
                width={35}
                height={35}
                loading="eager"
              />
            </button>
            <div className="ml-2">
              <a
                href={websiteUrl+(isMadhya?'/madhya-pradesh':isRajasthan?'/rajasthan':isUP?'/uttar-pradesh':isHR?'/haryana':isBH?'/bihar':isDL?'/delhi-news':'/')}
                onClick={() => {
                  let eventName = `TopBar-LogoClick`;
                  sendGAEvent({
                    eventName: eventName,
                    pageTitle: document?.title,
                    pageLocation: window?.location?.href,
                  });
                  setIsDrawerOpen(false);
                }}
              >
                <img
                  src={
                    isDrawerOpen
                      ? siteInfo?.siteLogo
                      : isRajasthan
                      ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/frame-2994.png"
                      : isMadhya
                      ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/frame-3068.png": isHR? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/haryana_tak_light.png": isBH?"https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/bihar_tak_light.png":isUP?"https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/uptak_light.png":isDL?"https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/delhi_tak_light.png"
                      : siteInfo?.siteLogo
                  }
                  alt={isMadhya?'MP Tak':isRajasthan?'Rajasthan Tak':isUP?'UP Tak':isHR?'Haryana Tak':isBH?'Bihar Tak':isDL?'Delhi Tak':siteInfo?.siteName}
                  title={isMadhya?'MP Tak':isRajasthan?'Rajasthan Tak':isUP?'UP Tak':isHR?'Haryana Tak':isBH?'Bihar Tak':isDL?'Delhi Tak':siteInfo?.siteName}
                  loading="eager"
                  fetchPriority="high"
                  height={isMadhya ? 30 : 35}
                  width={90}
                  // className={`${isMadhya?' height-[30px] width-[auto]':'height-[35px]'}`}
                />
              </a>
            </div>
          </div>
          <div className="flex space-x-2 justify-end ml-auto">
            <NavLink
              exact={true}
              className={"text-primary"}
              href={websiteUrl + "/aajtak-livetv"}
              title={""}
              onClick={() => {
                let eventName = `TopBar-ReadClick`;
                sendGAEvent({
                  eventName: eventName,
                  pageTitle: document?.title,
                  pageLocation: window?.location?.href,
                });
              }}
            >
              <SVG
                src="/assets/icons/svg/Live-msite.svg"
                className=" mr-2"
                title="home"
              />
            </NavLink>
          </div>

          <div className="flex space-x-2 ml-2 max-w-[50%]">
            {/* Search Box */}
            <div className="flex items-center max-w-auto">
              {isSearchBoxOpen ? (
                <>
                  <div className="flex items-center">
                    <div className="relative">
                      <input
                        id="search"
                        className={` placeholder:italic placeholder:text-slate-400 block w-full bg-white border border-slate-300 rounded-md py-1 pl-3 pr-10 shadow-sm focus:outline-none focus:border-red-300 focus:ring-red-300 focus:ring-1 sm:text-sm max-h-[40px]`}
                        placeholder="यहां खोज करें"
                        type="text"
                        name="search"
                        minLength={1}
                        value={searchText}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <div
                        role="button"
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                        onClick={() => handleSearch()}
                      >
                        <img
                          className="text-gray-500"
                          loading="eager"
                          src="/assets/icons/svg/search.svg"
                          alt="Search Icon"
                          height={20}
                          width={20}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => setIsSearchBoxOpen(false)}
                    className="focus:outline-none rounded-sm p-1"
                  >
                    <span className="h-6 w-6 text-black">
                      <SVG src="/assets/icons/svg/close.svg" />
                    </span>
                  </button>
                </>
              ) : (
                <button className="text-gray-700 focus:outline-none mr-4 md:hidden">
                  <img
                    loading="eager"
                    height={24}
                    width={24}
                    src="/assets/icons/svg/search.svg"
                    alt="Search Icon"
                    onClick={() => {
                      let eventName = `TopBar-Search`;
                      sendGAEvent({
                        eventName: eventName,
                        pageTitle: document?.title,
                        pageLocation: window?.location?.href,
                      });
                      setIsSearchBoxOpen(true);
                    }}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
        <MobileMenuSlider header_menu={header_menu} />
      </nav>
      {/* Drawer overlay */}
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-50 transition-opacity ${
          isDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsDrawerOpen(false)}
      ></div>

      {/* Drawer menu */}
      <section>
        <div
          className={`fixed inset-y-0 left-0 overflow-y-auto bottom-[65px] transform w-screen text-slate-700 shadow-xl z-50 bg-white transition-transform ${
            isDrawerOpen ? "translate-x-0" : "-translate-x-full"
          }`}
          style={{ zIndex: 101 }}
        >
          <div
            className={`flex flex-col ${
              isMadhya
                ? "bg-[#0C8A70]"
                : isRajasthan
                ? "bg-[#FFA51A]"
                :isHR?'bg-[#EFF8EC]':isBH?'bg-[#FAEDF5]':isUP?'bg-[#feedeb]':isDL?'bg-[#fbd6d6]': "bg-primary"
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="ml-2"> 
                <a href={websiteUrl+(isMadhya?'/madhya-pradesh':isRajasthan?'/rajasthan':isUP?'/uttar-pradesh':isHR?'/haryana':isBH?'/bihar':isDL?'/delhi-news':'/')} onClick={() => setIsDrawerOpen(false)}>
                  <img
                    src={
                      isDrawerOpen
                        ? isRajasthan
                          ? "/assets/brand-logo-2_rjtak.png"
                          : isMadhya
                          ? "/assets/brand-logo-2-mp.png"
                          : isHR
                          ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/haryana_tak_light.png"
                          : isBH
                          ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/bihar_tak_light.png"
                          : isUP
                          ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/uptak_light.png"
                          : isDL
                          ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/delhi_tak_light.png"
                          : siteInfo?.siteLogo2
                        : isRajasthan
                        ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/frame-2994.png"
                        : isMadhya
                        ? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/frame-3068.png": isHR? "https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/haryana_tak_light.png": isBH?"https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/bihar_tak_light.png":isUP?"https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/uptak_light.png":isDL?"https://akm-img-a-in.tosshub.com/lingo/nwtak/resources/delhi_tak_light.png"
                        : siteInfo?.siteLogo
                    }
                    alt={isMadhya?'MP Tak':isRajasthan?'Rajasthan Tak':isUP?'UP Tak':isHR?'Haryana Tak':isBH?'Bihar Tak':isDL?'Delhi Tak':siteInfo?.siteName}
                    className="ps-4 pt-2"
                    title={isMadhya?'MP Tak':isRajasthan?'Rajasthan Tak':isUP?'UP Tak':isHR?'Haryana Tak':isBH?'Bihar Tak':isDL?'Delhi Tak':siteInfo?.siteName}
                    loading="eager"
                    height={40}
                    width={130}
                  />
                </a>
              </div>

              <button
                onClick={() => setIsDrawerOpen(false)}
                className="focus:outline-none rounded-sm p-2"
              >
                <span className={`h-6 w-6 ${isDL||isUP||isBH||isHR?'text-black':'text-white'}`}>
                  <SVG src="/assets/icons/svg/close.svg" />
                </span>
              </button>
            </div>
            <div className="flex my-2 mx-4 h-[40px]">
              <div className="flex items-center justify-center w-full">
                <div className="relative w-full">
                  <input
                    id="search"
                    className={` placeholder:italic placeholder:text-slate-400 block w-full bg-white border border-slate-300 rounded-md py-1 pl-3 pr-10 shadow-sm focus:outline-none focus:border-primary-300 focus:ring-primary-300 focus:ring-1 sm:text-sm max-h-[40px]`}
                    placeholder="यहां खोज करें"
                    type="text"
                    name="search"
                    value={searchText}
                    onKeyDown={handleKeyPress}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                    onClick={() => handleSearch()}
                  >
                    <img
                      className="text-gray-500"
                      loading="eager"
                      height={20}
                      width={20}
                      src="/assets/icons/svg/search.svg"
                      alt="Search Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul className="divide-y divide-gray-200 bg-white dark:divide-gray-800 rounded-t-xl pt-2">
            {header_menu?.map((item, index) => (
              <li
                key={index}
                id={item?.id}
                className="hover:bg-primary-300 group"
                onClick={() => {
                  if (item?.sub_menu?.length > 0) {
                    setIsSubCategOpen(!IsSubCategOpen);
                  } else {
                    setIsDrawerOpen(false);
                  }
                }}
              >
                {item?.sub_menu?.length > 0 ? (
                  <div title={item?.title}>
                    <div className="flex items-center align-middle justify-between space-x-4 rtl:space-x-reverse border-l-4 border-l-white hover:border-l-4 hover:border-l-primary py-2 px-4">
                      <p
                        onClick={() => {
                          let eventName = `HamburgerMenu - ${item?.title} Click`;
                          sendGAEvent({
                            eventName: eventName,
                            pageTitle: document?.title,
                            pageLocation: window?.location?.href,
                          });
                          setIsDrawerOpen(false);
                        }}
                        className="text-lg font-bold truncatetext-white hover:text-primary"
                        id={"dropdownHoverButton_" + index}
                        data-dropdown-toggle={"dropdownHover_" + index}
                      >
                        {item?.svg && (
                          <div className="w-4 h-4 inline mx-2">
                            <SVG className="w-4 h-4 inline" src={item?.svg} />
                          </div>
                        )}
                        <a href={websiteUrl + item?.canonical_url}>
                          {item?.title}
                        </a>
                      </p>
                      {item?.sub_menu?.length > 0 && (
                        <svg
                          className="w-2 h-2 ml-1"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                          onClick={() => setIsSubCategOpen(!IsSubCategOpen)}
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ) : (
                  <a
                    onClick={() => {
                      let eventName = `HamburgerMenu - ${item?.title} Click`;
                      sendGAEvent({
                        eventName: eventName,
                        pageTitle: document?.title,
                        pageLocation: window?.location?.href,
                      });
                    }}
                    href={websiteUrl + item?.canonical_url}
                    title={item?.title}
                  >
                    <div className="flex items-center align-middle justify-between space-x-4 rtl:space-x-reverse border-l-4 border-l-white hover:border-l-4 hover:border-l-primary py-2 px-4">
                      <p
                        onClick={() => setIsDrawerOpen(false)}
                        className="text-lg font-bold truncatetext-white hover:text-primary"
                        id={"dropdownHoverButton_" + index}
                        data-dropdown-toggle={"dropdownHover_" + index}
                      >
                        {item?.svg && (
                          <div className="w-4 h-4 inline mx-2">
                            <SVG className="w-4 h-4 inline" src={item?.svg} />
                          </div>
                        )}
                        {item?.title}
                      </p>
                    </div>
                  </a>
                )}

                {/* Dropdown menu */}
                {item?.sub_menu?.length > 0 && IsSubCategOpen && (
                  <div
                    id={"dropdownHover_" + index}
                    className=" hidden w-full bg-white divide-y divide-gray-200 rounded-lg shadow-lg group-hover:block"
                  >
                    <ul
                      className="py-1 text-gray-700 dark:text-gray-200"
                      aria-labelledby={"dropdownHoverButton_" + index}
                    >
                      {item?.sub_menu?.map((sub_item, index2) => (
                        <li key={index2}>
                          <a
                            href={
                              websiteUrl +
                              (sub_item?.canonical_url
                                ? sub_item?.canonical_url.includes("www.")?"https://www.mumbaitak.in":sub_item?.canonical_url
                                : "")
                            }
                            onClick={() => {
                              let eventName = `HamburgerMenu - ${item?.title} - ${sub_item.title} Click`;
                              sendGAEvent({
                                eventName: eventName,
                                pageTitle: document?.title,
                                pageLocation: window?.location?.href,
                              });
                              setIsDrawerOpen(false);
                            }}
                            className="block px-4 py-2 mx-2 font-medium hover:bg-primary-300"
                          >
                            {sub_item?.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </section>
      {/* <style>
        {`
       .vdo_floating {
          bottom: 80px !important;
        }
        `}
      </style> */}
    </>
  );
};

export default MobileNavbar;
